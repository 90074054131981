import * as React from "react"
import Layout from '../components/Layout'
import Button from 'react-bootstrap/Button';
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Helmet from 'react-helmet';

import "/_custom.scss"

import * as classes from "../components/Site.module.scss"

import { StaticImage } from "gatsby-plugin-image"
import ElmLogo from "../images/es_horizontal_logo.jpg"
import NextLogo from "../images/nextSailorlogoColorDark.png"
import Favicon from "../images/favicon.ico";

const Work = () => {
  return (
    <Layout>
      <Helmet>
        <title>Work - Kevin Blank - kevinblank.com</title>
        <link rel="icon" type="image/x-icon" href={Favicon}></link>
      </Helmet>
      <main>
        <div >
        <Container>
          <Row>
            <Col>
              <h1 className={classes.subPageTitle}>Work</h1>           
            </Col>
          </Row>
        </Container>
        </div>
        <div>
        <Container>
          <Row>
            <Col style={bigSpacing}>
                <a href="https://elmstreet.com" target="_blank">
                    <StaticImage src="../images/es_horizontal_logo.jpg" alt="Elm Street logo" />
                </a>
                <div style={contentDiv}>
                    <h3>Elm Street Technology</h3>
                    <h5 style={subTitle}>Senior Developer / Lead Developer Marketing Team</h5>
                    <p><a href="https://elmstreet.com" target="_blank">https://elmstreet.com</a></p>
                </div>
                <div style={subContentDiv}>
                    <p>
                        I've been with Elm Street since October of 2018 when my previous company (NMD, Inc./eMerge) as acquired into the Elm Street family. As the
                        lead developer on the Marketing team and the employee with the most experience in email marketing and email marketing infrastructure, I frequently
                        work with other teams in the company to help build out the components and integrations to help enhance our email and drip campaign tools inside our 
                        suite of products. I also interface with other teams like the product team and UI/UX team to facilitate brand compliance from the technical side.
                    </p>
                    <p>
                        Duties include:
                    </p>
                    <ul>
                        <li>Setting up and managaging the infrastructure for the front-end marketing sites. (React/GatsbyJS)</li>
                        <li>Trainging and working with the designers and devs on the marketing team to build out marketing campaigns.</li>
                        <li>Build and maintain the API layers to push leads into Salesforce, eMerge, and other CRMs based on requirement from stakeholders.</li>
                        <li>Manage the hosting of custom websites (including WordPress) for customers.</li>
                        <li>Build and maintain the custom landing pages for bespoke marketing campaigns for high-end customers. (custom web/React/NextJS)</li>
                        <li>Build out headless e-commerce solution with custom order flow to handle print products with user customization. Utilizing a combination of React/NextJS/Vendure.</li>
                        <li>Manage and support the eMerge email marketing product including small feature request and changes. (PHP/MySQL/JS/Python)</li>
                    </ul>
                </div>
            </Col>
            <Col style={bigSpacing}>
                <a href="https://nextsailor.com" target="_blank">
                    <StaticImage src="../images/nextSailorlogoColorDark.png" alt="nextSailor logo" />
                </a>
                <div style={contentDiv}>
                    <h3>nextSailor</h3>
                    <h5 style={subTitle}>Founder/Owner</h5>
                    <p><a href="https://elmstreet.com" target="_blank">https://nextsailor.com</a></p>
                </div>
                <div style={subContentDiv}>
                    <p>
                        I started nextSailor in 2013 as a way to allow yacht clubs to offer regatta registration for their events when they weren't set up
                        to handle accepting credit cards online. I've since made contacts who love my simple approach to functionality and UI/UX and I'm now working 
                        with them to supply regatta scoring solutions, websites, and other regatta services with a modern approach.
                    </p>
                    <p>
                        Because I've built this all on my own and I have no partners or employees at this time, my focus on development is to keep the UI/UX simple and keep the
                        backend standardized and in the style required by the framework. This keeps my support time low when interacting with customers so I'm not overwhelming
                        myself with customer training and fielding questions.
                    </p>
                    <p>
                        nextSailor details:
                    </p>
                    <ul>
                        <li>The original codebase is an MVC structure written in PHP from the CodeIgniter 3 framework.</li>
                        <li>Where more advanced UI/UX is required, I employ React to provide a better interface that's easier to maintain.</li>
                        <li>The app lives in Google Cloud utilizing a LAMP stack.</li>
                        <li>nextSailor sites are a product I offer to sailing organizations in need of simple and fast websites. These are built on a multi-site configuration
                            of GatsbyJS and use the nextSailor public API to add dynamic functionality.</li> 
                        <li>Static site hosting is handled by Netlify to simplified the deployment and hosting.</li>
                    </ul>
                </div>
            </Col>
          </Row>
        </Container>
        </div>
      </main>
    </Layout>
  )
}

export default Work

// styles
const bigSpacing = {
    margin: "10px 50px",
}

const contentDiv = {
    marginTop:"30px"
}

const subContentDiv = {
    marginTop:"40px"
}

const subTitle = {
    color: '#666666',
    fontWeight: 100
}

const centerCenter = {
  position: "relative",
  left:" 50%",
  top: "50%",
  transform: "translate(-50%, -50%)",
  marginLeft: "6vw"
}